/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'trash-active': {
    width: 18,
    height: 20,
    viewBox: '0 0 18 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.012 4.476l3.063.82a1 1 0 01-.518 1.932L3.966 4.123a1 1 0 11.517-1.932l3.063.82a3.001 3.001 0 015.466 1.465zm-1.06-.284a2 2 0 00-3.346-.897l3.346.897zM15 8H3l1.103 10.108a1 1 0 00.994.892h7.806a1 1 0 00.994-.892L15 8zm-9.403 2.049a.5.5 0 00-.445.55l.523 4.972a.5.5 0 00.994-.105l-.522-4.972a.5.5 0 00-.55-.445zM8.5 10.5a.5.5 0 011 0v5a.5.5 0 01-1 0v-5zm3.897-.451a.5.5 0 00-.55.445l-.522 4.972a.5.5 0 10.995.105l.522-4.973a.5.5 0 00-.445-.55z" _fill="#fff"/>'
  }
})
